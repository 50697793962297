type FieldDefinition = {
    name: string;
    type: "string" | "number" | "bool";
    sortOrder?: number;
    sortDirection?: "asc" | "desc";
}

type DataSourceDefinition = {
    id: string;
    viewName: string;
    maxRecords: number;
    whereClause: string;
    loadRecents: boolean;
    distinctRows: boolean;
    dynamicLoading?: boolean;
    fields: FieldDefinition[];
}

export const local_dsReviewClasses: DataSourceDefinition = {
    id: 'local_dsReviewClasses',
    viewName: 'aviw_Arena_ReviewClasses',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields: [
        { name: "ID", type: "number" },
        { name: "Name", type: "string" }
    ]
}

export const local_dsClassificationCode: DataSourceDefinition = {
    id: 'local_dsClassificationCodes',
    viewName: 'aviw_Assets_ClassificationCodesLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields: [
        { name: "ID", type: "number" },
        { name: "Name", type: "string" },
        { name: "Title", type: "string" },
        { name: "NameAndTitle", type: "string" }
    ]
}

export const local_dsTopics: DataSourceDefinition = {
    id: 'local_dsTopics',
    viewName: 'aviw_Arena_TopicsLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "NameAndDescription", type: "string", sortOrder: 1, sortDirection: "asc" }]
}

export const local_dsProjectCodes: DataSourceDefinition = {
    id: 'local_dsProjectCodes',
    viewName: 'aviw_Assets_ProjectCodesLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "OrgUnit", type: "string" }]
}

export const local_dsDisciplines: DataSourceDefinition = {
    id: 'local_dsDisciplines',
    viewName: 'aviw_Assets_DisciplinesLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "Title", type: "string" },
        {name: "NameAndTitle", type: "string" }]
}

export const local_dsProjectPhases: DataSourceDefinition = {
    id: 'local_dsProjectPhases',
    viewName: 'aviw_Scope_ProjectPhasesLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string", sortOrder: 2, sortDirection: "asc" },
        {name: "Title", type: "string" },
        {name: "SortOrder", type: "string", sortOrder: 1, sortDirection: "asc" }]
}

export const local_dsMainAreas: DataSourceDefinition = {
    id: 'local_dsMainAreas',
    viewName: 'aviw_Assets_MainAreasLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "Title", type: "string" }]
}

export const local_dsPersonsLkp: DataSourceDefinition = {
    id: 'local_dsPersonsLkp',
    viewName: 'sviw_System_PersonsLookupWithRoles',
    maxRecords: 25,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    dynamicLoading: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "MobileNo", type: "string" },
        {name: "Email", type: "string" },
        {name: "Represents", type: "string" }]
}

export const local_dsOrgUnitsLkp: DataSourceDefinition = {
    id: 'local_dsOrgUnitsLkp',
    viewName: 'sviw_System_OrgUnits',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "NamePath", type: "string" },
        {name: "IdPath", type: "string" },
        {name: "Parent_ID", type: "number"},
        {name: "UnitType", type: "string"},
        {name: "HasNodes", type: "bool"},
        {name: "NameAndTitle", type: "string", sortOrder: 1, sortDirection: "asc"}] //field OrgUnit to be used?
}

export const local_dsSubProjects: DataSourceDefinition = {
    id: 'local_dsSubProjects',
    viewName: 'aviw_Scope_SubProjects',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" }]
}

export const local_dsMetaLookup: DataSourceDefinition = {
    id: 'local_dsMetaLookup',
    viewName: 'aviw_Assets_MetaObjectsWithHierarchi',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number"},
        {name: "Name", type: "string" },
        {name: "TypeAndName", type:"string"},
        {name: "Description", type:"string"}]
}

export const local_dsMetaFields: DataSourceDefinition = {
    id: 'local_dsMetaFields',
    viewName: 'aviw_Assets_Setup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "Meta1", type: "string"},
        {name: "Meta2", type: "string" },
        {name: "Meta3", type: "string" },
        {name: "Meta4", type: "string" },
        {name: "Meta5", type: "string" },
        {name: "Meta6", type: "string" },
        {name: "Meta7", type: "string" },
        {name: "Meta8", type: "string" },
        {name: "Meta9", type: "string" },
        {name: "Meta10", type: "string" },
        {name: "Meta11", type: "string" },
        {name: "Meta12", type: "string" },
        {name: "Meta13", type: "string" },
        {name: "Meta14", type: "string" },
        {name: "Meta15", type: "string" }]
}

export const local_dsRetentionCodes: DataSourceDefinition = {
    id: 'local_dsRetentionCodes',
    viewName: 'atbv_Arena_RetentionCodes',
    maxRecords: -1,
    whereClause: "Closed IS NULL",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "Code", type: "string", sortOrder: 1, sortDirection: "asc"},
        {name: "Description", type: "string" }]
}

export const local_dsVoidReasons: DataSourceDefinition = {
    id: 'local_dsVoidReasons',
    viewName: 'aviw_Arena_VoidReasons',
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string" },
        {name: "VoidReason_ID", type: "number"}]
}

export const local_dsExtProcesses: DataSourceDefinition = {
    id: 'local_dsExtProcesses',
    viewName: 'atbv_Arena_ExternalProcesses',
    maxRecords: -1,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string" }]
}

export const local_dsExtProStatuses: DataSourceDefinition = {
    id: 'local_dsExtProStatuses',
    viewName: 'aviw_Arena_ExternalProcessesStatusesLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string" }]
}

export const local_dsDocumentPackages: DataSourceDefinition = {
    id: 'local_dsDocumentPackages',
    viewName: 'aviw_Arena_DocumentPackagesLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string" },
        {name: "Title", type: "string" },
        {name: "NameAndTitle", type: "string", sortOrder: 1, sortDirection: "asc" },
        {name: "ID", type: "number" }]
}

export const local_dsDistReqTypes: DataSourceDefinition = {
    id: 'local_dsDistReqTypes',
    viewName: 'aviw_Arena_DistributionRequirementTypesLookup',
    maxRecords: -1,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string", sortOrder: 1, sortDirection: "asc" }]
}

export const local_dsCompanies: DataSourceDefinition = {
    id: 'local_dsCompanies',
    viewName: 'sviw_System_Companies',
    maxRecords: -1,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string" },
        {name: "ID", type: "number" },
        {name: "CompanyNo", type: "string" }]
}

export const local_dsPlannedChangeLkp: DataSourceDefinition = {
    id: 'local_dsPlannedChangeLkp',
    viewName: 'aviw_Arena_DocumentsPlannedChangeStatuses',
    maxRecords: 25,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string" },
        {name: "Description", type: "string" }]
}

export const local_dsCodeTypes: DataSourceDefinition = {
    id: 'local_dsCodeTypes',
    viewName: 'atbv_Arena_CodeTypes',
    maxRecords: 25,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string" },
        {name: "Sequence", type: "number", sortOrder: 1, sortDirection: "asc"}],
    
}

export const local_dsCodesLookup: DataSourceDefinition = {
    id: 'local_dsCodesLookup',
    viewName: 'aviw_Arena_CodesLookup2',
    maxRecords: 25,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "Seq", type: "string" },
        {name: "ID", type: "number" },
        {name: "Name", type: "string" },
        {name: "Title", type: "string" }]
}

export const local_dsChecklistsLkp: DataSourceDefinition = {
    id: 'local_dsChecklistsLkp',
    viewName: 'aviw_Check_ChecklistsLookup',
    maxRecords: 25,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number" },
        {name: "Name", type: "string" },
        {name: "Title", type: "string" },
        {name: "AccessOrgUnit_ID", type: "number" }]
}

export const local_dsDocProcesses: DataSourceDefinition = {
    id: 'local_dsDocProcesses',
    viewName: 'aviw_Arena_DocumentProcessesLookup',
    maxRecords: 25,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields:
        [{name: "ID", type: "number" },
        {name: "Name", type: "string" },
        {name: "IdPath", type: "string" }]
}
